<template>
  <v-row
    align="center"
    justify="center"
  >
    <v-col class="text-center">
      <div class="video-container">
        <div
          v-if="!isMobile"
          class="video-container"
        >
          <video
            autoplay
            muted
            loop
          >
            <source
              src="video/supporter.mp4"
              type="video/mp4"
            >
            Your browser does not support the video tag.
          </video>
        </div>
        <div v-else>
          <div class="image-container">
            <img
              src="img/supporter.gif"
              alt="Supporter GIF"
            >
          </div>
        </div>
      </div>
      <p class="my-text">
        <a
          href="https://doneru.jp/housingeden"
          target="_blank"
          rel="noopener noreferrer"
          class="support-link"
        >
          {{ $t("message.supporter_01") }}
        </a>
      </p>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'NotificationsView',
    metaInfo: {
      title: 'Supporter',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'keywords', content: 'ff14 ハウジング,ハウジング ff14,ff14 housing,housing ff14,ff14ハウジング,ff14housing,ハウジング,ハウジングエデン,ff14,ファイナルファンタジー,housing,ffxiv,final fantasy,Housing Eden,housing eden' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        { name: 'description', content: 'FFXIV Housing Edenはファイナルファンタジー14の最新ハウジング情報を世界レベルでお届けするサイトです。今話題のツイートはもちろん、バズツイート、ハウジンガー名鑑、創作家具レシピまで網羅しています。' },
        { property: 'og:image', content: 'https://ff14eden.work/twitter_card.jpg?3' },
      ],
    },
    data () {
      return {
        // sns拡散
        sns: {
          twitter: 'https://twitter.com/intent/tweet?hashtags=HousingEden',
        },
        isMobile: false,
      }
    },
    mounted () {
      this.checkDeviceType()
      window.addEventListener('resize', this.checkDeviceType)
    },
    destroyed () {
      window.removeEventListener('resize', this.checkDeviceType)
    },
    methods: {
      checkDeviceType () {
        this.isMobile = window.innerWidth <= 768
        console.log(this.isMobile)
      },
    },
  }
</script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  margin: 0;
}

.video-container::before {
  display: block;
  content: "";
  width: 100%;
}

.video-container video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.my-text {
  margin: 20px 0;
}

.image-container {
  position: relative;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  margin: 0;
}

.image-container img {
  top: 0;
  left: 0;
  max-width: 100%;
  height: 100%;

}
</style>
